@import 'theme/main.scss';

.option {
  margin-top: 15px;
  margin-left: 50px;
  display: flex;

  .option-label {
    flex: 6;

    .option-text {
      background-color: #e6f7f5;
      color: #4a4a4a;
      font-family: monospace;
      font-weight: 600;
      font-size: 14px;
      float: left;
      padding: 10px;
    }
  }

  .option-list {
    flex: 20;

    &-chip {
      margin-right: 4px;
    }
  }

  .option-select {
    flex: 20;

    .select-form-control {
      min-width: 120px;
    }
  }

  .option-input {
    flex: 20;

    .option-textfield {
      font-weight: 400;
      color: #4a4a4a;

      &-label {
        color: #b5b6ba;
      }
    }
  }

  .option-uneditable {
    display: flex;
    flex: 20;
    height: 40px;
    background-color: #e6f7f5;
    color: #4a4a4a;
    font-family: monospace;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;

    .MuiIconButton-root {
      padding: 0 !important;
      margin-left: 5px;
    }
  }

  .row-spacer {
    flex: 1;
  }
}
