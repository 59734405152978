@import 'theme/main.scss';

$danger-color: map-get(
  $map: $theme-colors,
  $key: danger,
);

.auto-complete {
  &-container {
    top: calc(#{$navbarHeight} - 10px);
    left: calc(#{$navbarHeight} - 10px);
    width: calc(90vw - #{$sidebarWidth} - #{$navbarHeight});
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14) !important;
    max-height: 50vh;
    overflow-y: scroll;
  }

  &-patient {
    width: 100%;
    background-color: white;
    padding: 5px 15px;

    &:hover,
    &:active {
      cursor: pointer;
      background-color: #ededf0;
    }
  }

  .status {
    margin-right: 5px;

    &-active {
      color: $green-5;
    }

    &-inactive {
      color: $danger-color;
    }
  }
}
