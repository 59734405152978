$navbarHeight: 80px;
$sidebarWidth: 400px;

$gray-5: #f6f5ef;
$gray-10: #f0eee5;
$gray-20: #c4c4c4;

$green-5: #d9ea75;
$green-80: #0d4657;

$color-black: #000000;
$color-disabled: $gray-20;
$light-gray: $gray-10;

$checkbox-size: 26px;
$radio-size: 22px;
$social-logo-size-mobile: 40px;

$primary-button-width: 240px;
$primary-button-height: 54px;
$primary-button-border-radius: 8px;

// Overwrite Bootstrap Theme
$theme-colors: (
  primary: #5988ff,
  secondary: #517bfb,
  danger: rgb(255, 42, 42),
  success: #26c5a8,
  warning: #f0d732,
  light: #f4f6fa,
  info: #0d85f4,
  dark: $color-black,
);

// Font Weights
$font-light: 300;
$font-normal: 400;
$font-bold: 700;

// Letter Spacing
$letter-spacing-label: 0.28em;

// link variant color
$link-color: #0d85f4;

//-------------------------------------Font sizes
//bootstrap font sizes
$font-size: (
  // 10px
  1: 0.625rem,
  // 12px
  2: 0.75rem,
  // 14px
  3: 0.875rem,
  // 16px
  4: 1rem,
  // 18px
  5: 1.125rem,
  // 20px
  6: 1.25rem,
  // 22px
  7: 1.375rem,
  // 24px
  8: 1.5rem,
  // 28px
  9: 1.75rem,
  // 32px
  10: 2rem,
  // 36px
  11: 2.25rem,
  // 44px
  12: 2.75rem,
  // 48px
  13: 3rem,
  // 52px
  14: 3.25rem,
  // 64px
  15: 4rem,
  // 80px
  16: 5rem
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  // 4px
  2: $spacer * 0.5,
  // 8px
  3: $spacer,
  // 16px
  4: $spacer * 1.5,
  // 24px
  5: $spacer * 2.2,
  // 35.2px
  6: $spacer * 3,
  // 48px
  7: $spacer * 4,
  // 64px
  8: $spacer * 5,
  // 80px
  9: $spacer * 6.42,
  // 102.72px
  10: $spacer * 7.5,
  // 120px
  11: $spacer * 9.2,
  // 147.2px,
  12: $spacer * 10,
  // 160px
  13: $spacer * 11,
  // 176px
  14: $spacer * 12.9,
  // 206.4px
  15: $spacer * 14.3,
  // 228.8px
  16: $spacer * 17.9,
  // 286.4px
  17: $spacer * 21.4,
  // 342.4px
  18: $spacer * 23,
  // 368px
  18_5: $spacer * 25,
  // 400px
  19: $spacer * 28,
  // 448px
  20: $spacer * 32,
  // 512px
  20_5: $spacer * 34.5,
  // 552px
  21: $spacer * 37,
  // 592px
  22: $spacer * 42,
  // 672px
  23: $spacer * 47.5,
  // 760px
  24: $spacer * 53.75,
  // 860px
);
