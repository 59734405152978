@import 'theme/main.scss';

.patient-view-container {
  background-color: white;

  $danger-color: map-get(
    $map: $theme-colors,
    $key: danger,
  );

  .button-margin {
    margin-left: 10px;
  }

  .cancel-button {
    color: $white;
    background-color: $danger-color;

    &:hover {
      background-color: darken($color: $danger-color, $amount: 15%);
    }
  }

  .status {
    margin-right: 5px;

    &-active {
      color: $green-5;
    }

    &-inactive {
      color: $danger-color;
    }
  }
}
