@import 'theme/main.scss';

$table-padding: 30px;
$danger-color: map-get(
  $map: $theme-colors,
  $key: danger,
);
$primary-color: map-get(
  $map: $theme-colors,
  $key: primary,
);

.dashboard-container {
  display: flex;
  margin: 0;
}

.sidebar {
  width: $sidebarWidth;
  flex-shrink: 0;

  .paper {
    width: $sidebarWidth;
    border-right: 0px;
    background-color: #4e525d;
  }

  .sidebar-item {
    text-align: left;
    padding-left: 35px;
    display: flex;
    height: 70px;
    color: $white;

    &.selected-item {
      background-color: #414550;

      &:hover {
        background-color: #414550;
      }
    }

    &.sidebar-header {
      color: $gray-10;
      font-size: 13px;
      font-weight: 600;

      .break-line {
        flex-grow: 1;
        border-bottom: 1px solid $gray-10;
        height: 0.25rem;
        display: flex;
        margin: 0 15px 0 10px;
      }
    }

    &-sublink {
      padding-left: 55px;
    }

    &-text {
      font-family: 'Verdana';
      font-size: 18px;
      font-weight: 500;

      &-sublink {
        font-size: 14px;
      }
    }
  }

  .chevron {
    margin-right: 10px;
    font-size: 28px;
  }

  .overflow {
    overflow-y: scroll;
  }
}

.spacer {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  height: $navbarHeight;
  background-color: map-get($map: $theme-colors, $key: primary);

  .vertical-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.app-bar {
  display: flex;
  justify-content: center;
  height: $navbarHeight;
  width: calc(100% - #{$sidebarWidth}) !important;
  padding-left: 10px;
  background-color: $white !important;
  color: $black !important;

  .search {
    font-size: 1.3rem !important;
  }

  .menu-icon {
    &:hover {
      cursor: pointer;
    }
  }
}

.menu-drawer {
  min-width: 250px;
}

.content {
  flex-grow: 1;
  background-color: #f5f5f7;
  min-height: 100vh;
  color: $black;

  svg.us-state-map {
    width: 100%;
  }
}

.table-container {
  margin: 50px $table-padding 20px $table-padding;
  width: calc(100% - #{$table-padding} - #{$table-padding}) !important;

  .add-new-button {
    margin-bottom: 10px;
    float: right;
  }

  .no-match {
    margin: 20px auto;
    text-align: center;

    .clear-search {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  thead {
    background-color: #eaeef2;

    th {
      padding: 5px 16px;
      border-bottom: none;

      .sort-arrow {
        fill: green;
        height: 0.8rem;
        width: 0.8rem;
      }

      .visually-hidden {
        border: 0;
        clip: 'rect(0 0 0 0)';
        height: 1;
        margin: -1;
        overflow: 'hidden';
        padding: 0;
        position: 'absolute';
        top: 20;
        width: 1;
      }
    }
  }

  tbody {
    tr {
      background-color: $white;

      .list-button {
        margin-right: 8px;
        color: $black;

        &:disabled {
          color: $gray-20;
        }
      }

      .table-chip {
        margin: 1px 4px 1px 0;
      }

      &:hover {
        background-color: rgba(89, 136, 255, 0.05);
      }
    }
  }
}

.new-asset-form {
  margin-top: 15px;

  .new-asset-textfield {
    margin-top: 8px;
  }

  .select-form-control {
    margin-top: 7px;
    width: 100%;
  }

  .action-buttons {
    margin-top: 15px;
    margin-bottom: 25px;
    float: right;

    .cancel-button {
      color: $white;
      background-color: $danger-color;

      &:hover {
        background-color: darken($color: $danger-color, $amount: 15%);
      }
    }

    .submit-button {
      color: $white;
      background-color: $primary-color;
      margin-left: 15px;

      &:hover {
        background-color: darken($color: $primary-color, $amount: 15%);
      }
    }
  }
}

.status-badge {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;

  &-active {
    background-color: $green-5;
  }

  &-inactive {
    background-color: $danger-color;
  }
}
