@import 'theme/main.scss';

.add-state-list {
  margin: 20px 0 10px 0;

  .state-chip {
    margin-right: 6px;
    margin-bottom: 6px;
  }
}
