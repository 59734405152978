$dot-size: 1.2rem;
$left-margin: 5px;

@keyframes flow {
  0% {
    transform: translateX(calc(-400% - 15px)) scaleX(1.3) scaleY(1.2);
  }
  /* 50%{
      transform: translateX(calc(-250% - 7.5px)) scale(1.2);
    } */
  40% {
    transform: translateX(-100%) scaleX(1.3) scaleY(1.2);
  }

  100% {
    transform: scale(0);
  }
}

.loader {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: calc(50% + 0.6rem);
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
}

.dot {
  width: $dot-size;
  height: $dot-size;
  border-radius: 50%;
  margin-left: $left-margin;
}

.dot:last-child {
  margin-left: 0;
  animation: 1.3s flow infinite ease-out;
}
