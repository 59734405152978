@use "sass:map";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300&display=swap');
@import './variables';
@import './responsive';
@font-face {
  font-family: 'Canela';
  src: local('Canela'), url(../assets/fonts/Canela-Thin.ttf) format('truetype');
}

@import 'node_modules/bootstrap/scss/bootstrap';

@mixin spaced-capital-letters {
  letter-spacing: $letter-spacing-label;
  text-transform: uppercase;
}

@mixin form-label {
  font-size: map-get($map: $font-size, $key: 2);
  @include spaced-capital-letters();
}

@mixin heading1 {
  font-size: map-get($map: $font-size, $key: 10);
  font-weight: $font-normal;
  font-family: Canela;
  font-style: normal;
}

@mixin disabled {
  background-color: $color-disabled;
  border-color: $color-disabled;
}

@mixin primary-button-style {
  width: $primary-button-width;
  height: $primary-button-height;
  border-radius: $primary-button-border-radius;

  @include small {
    width: 100%;
  }
}

@mixin small-button-style {
  width: 115px;
  height: 37px;
  border-radius: 2px;
}

.primary-button {
  @include primary-button-style;
}

body {
  height: 100%;
  width: 100%;
  position: absolute;
  font-family: 'Roboto', sans-serif;
  font-weight: $font-light;
  color: $color-black;

  #root,
  .Container {
    height: inherit;
    padding: 0;
  }

  .h1 {
    @include heading1;
  }
}

.btn {
  &-primary {
    color: white;

    &:hover,
    &:focus,
    &:active,
    &:disabled {
      color: white;
    }
  }

  &-block {
    width: 100%;
  }

  &-outline-secondary {
    border-radius: 8px;

    &:hover,
    &:active,
    &.active {
      color: white;
    }
  }

  &:disabled {
    @include disabled;
  }
}

.spaced-capital-letters {
  @include spaced-capital-letters();
}

.bg-image {
  z-index: -1;
}

.sharing-logo {
  @include small {
    width: $social-logo-size-mobile;
    height: $social-logo-size-mobile;
  }
}

.progress {
  background-color: $gray-5;
  border-radius: 10px;
  height: 10px;

  &-bar {
    background-color: map-get($map: $theme-colors, $key: primary);
  }
}

.height-full {
  height: 100%;
  box-sizing: border-box;
}

.min-height-full {
  min-height: 100%;
}

.height-inherit {
  height: inherit;
}

.width-full {
  width: 100%;
}

.width-fit-content {
  width: fit-content;
}

.content-box {
  box-sizing: content-box;
}

.border-box {
  box-sizing: border-box;
}

.font-weight-light {
  font-weight: $font-light;
}

.font-weight-normal {
  font-weight: $font-normal;
}

.font-weight-bold {
  font-weight: $font-bold;
}

.font-italic {
  font-style: italic;
}

.form-group {
  .form-control {
    font-size: map-get($map: $font-size, $key: 4);
    font-weight: $font-light;
    color: $color-black;
    border-color: $color-black;
  }

  .form-label {
    @include form-label;
  }

  .dropdown-menu {
    width: 100%;
    border-color: $color-black;
    overflow-x: hidden;
  }

  .form-check {
    &-inline {
      display: flex;
      align-items: center;
    }

    &-label {
      font-size: map-get($map: $font-size, $key: 3);
      padding: map-get($map: $spacers, $key: 2);
      padding-left: map-get($map: $spacers, $key: 3);
    }

    &-input {
      border-color: $color-black;
      margin-block: 0;

      &:checked {
        background-color: map-get($map: $theme-colors, $key: primary);
      }

      &[type='checkbox'] {
        width: $checkbox-size;
        min-width: $checkbox-size;
        height: $checkbox-size;
      }

      &[type='radio'] {
        width: $radio-size;
        height: $radio-size;
      }
    }
  }
}

//----------------------------------------- MUI Overrides

.MuiLink-root {
  &:hover {
    cursor: pointer;
  }
}

.MuiFormControlLabel-root {
  margin-right: 0 !important;
}

.spinner {
  &-border {
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
  }
}

.justify-content-space-around {
  justify-content: space-around;
}

.auto-width {
  width: auto;
}

//----------------------------------------- Typography

@each $size, $value in $font-size {
  .fs-#{$size} {
    font-size: $value !important;
  }

  .fs-sm-#{$size} {
    @include media-breakpoint-up(sm) {
      font-size: $value !important;
    }
  }
}

@each $space, $value in $spacers {
  .height-#{$space} {
    height: $value;
    min-height: $value;
  }

  .height-sm-#{$space} {
    height: auto;
    @include media-breakpoint-up(sm) {
      height: $value !important;
      min-height: $value !important;
    }
  }

  .max-height-#{$space} {
    max-height: $value !important;
  }

  .min-height-#{$space} {
    min-height: $value !important;
  }

  .min-height-sm-#{$space} {
    min-height: auto;
    @include media-breakpoint-up(sm) {
      min-height: $value !important;
    }
  }

  .min-height-lg-#{$space} {
    min-height: auto;
    @include media-breakpoint-up(lg) {
      min-height: $value !important;
    }
  }

  .width-#{$space} {
    width: $value !important;
    min-width: $value !important;
  }

  .width-sm-#{$space} {
    width: auto;
    @include media-breakpoint-up(sm) {
      width: $value !important;
    }
  }

  .max-width-#{$space} {
    max-width: $value !important;
  }

  .max-width-sm-#{$space} {
    max-width: auto;

    @include media-breakpoint-up(sm) {
      max-width: $value !important;
    }
  }

  .min-width-#{$space} {
    min-width: $value !important;
  }

  .min-width-sm-#{$space} {
    min-width: auto;
    @include media-breakpoint-up(sm) {
      min-width: $value !important;
    }
  }

  .top-#{$space} {
    top: $value;
  }

  .top-md-#{$space} {
    top: auto;

    @include media-breakpoint-up(md) {
      top: $value !important;
    }
  }

  .right-#{$space} {
    right: $value;
  }

  .right-md-#{$space} {
    right: auto;

    @include media-breakpoint-up(md) {
      right: $value !important;
    }
  }

  .bottom-#{$space} {
    bottom: $value;
  }

  .bottom-md-#{$space} {
    bottom: auto;

    @include media-breakpoint-up(md) {
      bottom: $value !important;
    }
  }

  .left-#{$space} {
    left: $value;
  }

  .left-md-#{$space} {
    left: auto;

    @include media-breakpoint-up(md) {
      left: $value !important;
    }
  }

  .br-#{$space} {
    border-radius: $value;
  }
}
