@import 'theme/main.scss';

.analytics-container {
  margin: 20px 25px;
}

.analytics-card {
  &-description {
    font-size: 1rem;
  }

  .interactive-map {
    path:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}
