@import 'theme/main.scss';

$danger-color: map-get(
  $map: $theme-colors,
  $key: danger,
);

.close-button {
  float: right;
}

.provider-name {
  text-align: center;
}

.action-button-container {
  display: flex;
  justify-content: center;
  margin: 10px 0 10px 0;

  .cancel-button {
    color: $white;
    background-color: $danger-color;

    &:hover {
      background-color: darken($color: $danger-color, $amount: 15%);
    }
  }
}
